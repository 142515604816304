<template>
  <!-- SEO Bar -->
  <div v-bind:class="['seo-bar', isOpen ? 'is-open' : '']">
    <div class="row m-0 align-items-center">
      <Button
        v-bind:result="getSeoResult"
        v-bind:is-open="isOpen"
        v-on:toggle-open="isOpen = $event"
      />
      <div class="col p-0">
        <div v-bind:class="['seo-card', getSeoResult]">
          <div class="card-body">
            <h6 class="mb-0"><i class="fas fa-rocket"></i> SEO UYUMLULUĞU</h6>
            <hr />
            <template v-if="focusKeyword">
              <div class="d-block text-truncate" style="max-width: 285px">
                <b>Anahtar Kelime:</b>
                <span class="no-select ml-1" v-tooltip.bottom="getTooltip">{{
                  focusKeyword
                }}</span>
              </div>
              <hr />
            </template>
            <div class="seo-content custom-scrollbar">
              <ul class="seo-check-list font-14" style="padding-left: 1px">
                <li
                  v-for="item in items"
                  v-bind:key="item.name"
                  v-bind:class="getItemClass(item)"
                >
                  <SeoSlug
                    v-if="item.name == 'SeoSlug'"
                    class="py-1"
                    v-bind:value="slug"
                    v-bind:focus-keyword="focusKeyword"
                    v-on:update-completed="item.completed = $event"
                  />
                  <KeywordDensity
                    v-if="item.name == 'KeywordDensity'"
                    class="py-1"
                    v-bind:value="description"
                    v-bind:focus-keyword="focusKeyword"
                    v-on:update-completed="item.completed = $event"
                  />
                  <ItemDescription
                    v-if="item.name == 'ItemDescription'"
                    class="py-1"
                    v-bind:value="description"
                    v-bind:focus-keyword="focusKeyword"
                    v-on:update-completed="item.completed = $event"
                  />
                  <SeoMetaTitle
                    v-if="item.name == 'SeoMetaTitle'"
                    class="py-1"
                    v-bind:value="metaTitle"
                    v-on:update-completed="item.completed = $event"
                  />
                  <SeoMetaDescription
                    v-if="item.name == 'SeoMetaDescription'"
                    class="py-1"
                    v-bind:value="metaDescription"
                    v-on:update-completed="item.completed = $event"
                  />
                  <MetaDescriptionContainsKey
                    v-if="item.name == 'MetaDescriptionContainsKey'"
                    class="py-1"
                    v-bind:focus-keyword="focusKeyword"
                    v-bind:value="metaDescription"
                    v-on:update-completed="item.completed = $event"
                  />
                  <ImageAlt
                    v-if="item.name == 'ImageAlt' && images.length"
                    class="py-1"
                    v-bind:value="images"
                    v-bind:focus-keyword="focusKeyword"
                    v-on:update-completed="item.completed = $event"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./SEO Bar -->
</template>

<script>
import Button from "./components/Button/Index";
import SeoMetaDescription from "./components/MetaDescription/Index.vue";
import SeoMetaTitle from "./components/MetaTitle/Index.vue";
import SeoSlug from "./components/Slug/Index.vue";
import ItemDescription from "./components/ItemDescription/Index.vue";
import MetaDescriptionContainsKey from "./components/MetaDescriptionContainsKey/Index.vue";
import KeywordDensity from "./components/KeywordDensity/Index.vue";
const ImageAlt = () => import("./components/ImageAlt/Index.vue");

export default {
  name: "WeSeoBar",
  components: {
    Button,
    SeoMetaDescription,
    SeoMetaTitle,
    SeoSlug,
    ItemDescription,
    MetaDescriptionContainsKey,
    KeywordDensity,
    ImageAlt,
  },
  props: {
    focusKeyword: {
      default: null,
    },
    metaTitle: {
      default: null,
    },
    metaDescription: {
      default: null,
    },
    description: {
      default: null,
    },
    images: {
      default: () => [],
    },
    slug: {
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      items: [
        {
          name: "SeoSlug",
          completed: false,
        },
        {
          name: "KeywordDensity",
          completed: false,
        },
        {
          name: "ItemDescription",
          completed: false,
        },
        {
          name: "SeoMetaTitle",
          completed: false,
        },
        {
          name: "SeoMetaDescription",
          completed: false,
        },
        {
          name: "MetaDescriptionContainsKey",
          completed: false,
        },
        {
          name: "ImageAlt",
          completed: false,
        },
      ],
    };
  },
  methods: {
    getItemClass(item) {
      let result = "uncompleted";
      result = item.completed ? "completed" : "uncompleted";
      if (item.name == "ImageAlt" && !this.images.length) result = "d-none";
      return result;
    },
    calculateResult(percentage) {
      let result = "bad";

      if (percentage >= 40 && percentage <= 79) {
        result = "mid";
      } else if (percentage < 40) {
        result = "bad";
      } else if (percentage > 79) {
        result = "good";
      }

      return result;
    },
  },
  computed: {
    getTooltip() {
      if (this.focusKeyword && this.focusKeyword.length >= 30) {
        return this.focusKeyword;
      }
    },
    completedItems() {
      return this.items.filter((item) => item.completed);
    },
    unCompletedItems() {
      return this.items.filter((item) => !item.completed);
    },
    getSeoResult() {
      let percentage = 0;

      const completedItemsLength = this.completedItems.length;

      if (completedItemsLength) {
        percentage = (completedItemsLength / this.items.length) * 100;
      }

      return this.calculateResult(percentage);
    },
  },
};
</script>

<style lang="scss">
/* ----- SEO BAR ----- */
.seo-bar {
  position: fixed;
  top: 50%;
  margin-top: -180px;
  right: -350px;
  z-index: 1020;
  transition: 0.4s all;
}

/*.seo-bar.is-open .seo-reaction {
    color: #343a40;
  }*/

.seo-reaction:hover {
  color: #727272;
}

.seo-bar.is-open {
  right: 0;
}

.seo-card {
  background-color: #ffffff;
  border-radius: 0.25rem;
  max-width: 350px;
}

.seo-card.good {
  border-left: 3px solid #2ed573;
}

.seo-card.mid {
  border-left: 3px solid #ffb120;
}

.seo-card.bad {
  border-left: 3px solid #ff4757;
}

.seo-content {
  font-size: 14px;
  max-height: 250px;
  overflow-y: auto;
}

.seo-reaction {
  line-height: 1px;
  padding: 10px;
  background-color: #ffffff;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  cursor: pointer;
  transition: 0.4s all ease;
  background-color: rgba(128, 128, 128, 0.295);
}

.seo-card,
.seo-reaction:hover {
  box-shadow: 1px 3px 5px rgba(128, 128, 128, 0.295);
}

.seo-reaction.active {
  color: #ffffff;
}
.seo-reaction.active.good {
  background-color: #2ed573;
}

.seo-reaction.active.mid {
  background-color: #ffb120;
}

.seo-reaction.active.bad {
  background-color: #ff4757;
}

.seo-check-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.seo-check-list li {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.1rem;
}

.seo-check-list .completed::before {
  content: "\f058";
  float: left;
  font-size: 900;
  font-family: "Font Awesome 5 Free";
  margin-right: 0.5rem;
  color: #2ed573;
}

.seo-check-list .uncompleted::before {
  content: "\f057";
  float: left;
  font-size: 900;
  font-family: "Font Awesome 5 Free";
  margin-right: 0.5rem;
  color: #ff4757;
}

/* ----- SEO BAR ----- */
</style>