<template>
  <div>
    <span
      >Meta Açıklama <b>130</b> - <b>350</b> arası karakter uzunluğunda
      olmalı.</span
    >
  </div>
</template>
<script>
export default {
  name: "MetaDescription",
  props: {
    value: {
      default: null,
    },
  },
  computed: {
    getValidation() {
      return (
        this.value !== null &&
        this.value.length >= 130 &&
        this.value.length <= 350
      );
    },
  },
  watch: {
    getValidation: function (newVal) {
      this.$emit("update-completed", newVal);
    },
  },
  mounted() {
    this.$emit("update-completed", this.getValidation);
  },
};
</script>