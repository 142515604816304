<template>
  <div>
    <span>Meta Açıklama, <b>Anahtar Kelime</b>yi içermeli.</span>
  </div>
</template>
<script>
export default {
  name: "MetaDescriptionContainsKey",
  props: {
    value: {
      default: null,
    },
    focusKeyword: {
      default: null,
    },
  },
  computed: {
    getValidation() {
      const value = helper.toText(
        this.value ? this.value.toLocaleLowerCase("tr-TR") : null
      );
      return value.includes(
        this.focusKeyword ? this.focusKeyword.toLocaleLowerCase("tr-TR") : null
      );
    },
  },
  watch: {
    getValidation: function (newVal) {
      this.$emit("update-completed", newVal);
    },
  },
  mounted() {
    this.$emit("update-completed", this.getValidation);
  },
};
</script>