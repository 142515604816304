<template>
  <div>
    <span
      >Açıklama alanındaki anahtar kelime yoğunluğu: <b>%{{ percent }}</b
      >. Tavsiye edilen en fazla <b>%3</b></span
    >
  </div>
</template>
<script>
export default {
  name: "KeywordDensity",
  props: {
    value: {
      default: () => [],
    },
    focusKeyword: {
      default: null,
    },
  },
  methods: {
    calculatePercent(descriptionDensity, textValue) {
      const keyword = this.focusKeyword ? this.focusKeyword.split(" ") : null;
      let foundCounter = 0;

      if (keyword && keyword.length) {
        keyword.forEach((key) => {
          if (descriptionDensity && descriptionDensity.hasOwnProperty(key)) {
            foundCounter += descriptionDensity[key];
          }
        });
      }

      let result = foundCounter
        ? ((foundCounter / textValue.length) * 100).toFixed(2)
        : 0;
      return result;
    },
  },
  computed: {
    getValueText() {
      let result = "";

      if (this.value && this.value.length) {
        for (let i = 0; i < this.value.length; i++) {
          const element = this.value[i];
          const content = helper.toText(element.content);
          if (content && content.length) {
            result += ` ${content}`;
          }
        }
      }
      return result;
    },
    percent() {
      let result = 0;
      const textValue = this.getValueText;
      if (textValue) {
        const descriptionDensity = helper.frequencyChecker(textValue);
        result = this.calculatePercent(descriptionDensity, textValue);
      }
      return result;
    },
    getValidation() {
      return this.percent > 0 && this.percent <= 3;
    },
  },
  watch: {
    getValidation: function (newVal) {
      this.$emit("update-completed", newVal);
    },
  },
  mounted() {
    this.$emit("update-completed", this.getValidation);
  },
};
</script>