<template>
  <div>
    <span>Anahtar kelime <b>Slug</b> değerinde bulunmalı.</span>
  </div>
</template>
<script>
export default {
  name: "Slug",
  props: {
    value: {
      default: null,
    },
    focusKeyword: {
      default: null,
    },
  },
  computed: {
    getValidation() {
      const focusKeywordSlug = helper.slugify(this.focusKeyword);
      return this.value ? this.value.includes(focusKeywordSlug) : false;
    },
  },
  watch: {
    getValidation: function (newVal) {
      this.$emit("update-completed", newVal);
    },
  },
  mounted() {
    this.$emit("update-completed", this.getValidation);
  },
};
</script>