<template>
  <div>
    <span
      >Açıklama alanı <b>{{ descriptionCount | numberFormat }}</b> kelime
      içermektedir. En az <u>300</u> kelime tavsiye edilir.</span
    >
  </div>
</template>
<script>
export default {
  name: "ItemDescription",
  props: {
    value: {
      default: () => [],
    },
  },
  computed: {
    descriptionCount() {
      let result = 0;
      if (this.value && this.value.length) {
        for (let i = 0; i < this.value.length; i++) {
          const element = this.value[i];
          const content = element.content;
          if (content && content.length) {
            result += helper.toText(content).split(" ").length;
          }
        }
      }
      return result;
    },
    getValidation() {
      return this.value && this.value.length && this.descriptionCount >= 300;
    },
  },
  watch: {
    getValidation: function (newVal) {
      this.$emit("update-completed", newVal);
    },
  },
  mounted() {
    this.$emit("update-completed", this.getValidation);
  },
};
</script>