<template>
  <div>
    <span
      >Meta Başlık <b>35</b> - <b>60</b> arası karakter uzunluğunda
      olmalı.</span
    >
  </div>
</template>
<script>
export default {
  name: "MetaTitle",
  props: {
    value: {
      default: null,
    },
  },
  computed: {
    getValidation() {
      return (
        this.value !== null &&
        this.value.length >= 35 &&
        this.value.length <= 60
      );
    },
  },
  watch: {
    getValidation: function (newVal) {
      this.$emit("update-completed", newVal);
    },
  },
  mounted() {
    this.$emit("update-completed", this.getValidation);
  },
};
</script>