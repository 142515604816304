<template>
  <!-- Emoji Button -->
  <div v-bind:class="['seo-reaction', result, getIsOpen]" v-on:click="onOpen">
    <i v-bind:class="getIconClass"></i>
  </div>
  <!-- ./Emoji Button -->
</template>

<script>
export default {
  name: "Button",
  props: ["result", "isOpen"],
  data() {
    return {
      reactions: {
        good: "fa-smile",
        mid: "fa-meh",
        bad: "fa-frown",
      },
    };
  },
  methods: {
    onOpen() {
      this.$emit("toggle-open", !this.isOpen);
    },
  },
  computed: {
    getIconClass() {
      let textColor = "text-dark";

      if (!this.isOpen) {
        switch (this.result) {
          case "bad":
            textColor = "text-danger";
            break;

          case "mid":
            textColor = "text-warning";
            break;

          case "good":
            textColor = "text-success";
            break;
        }
      }

      return `far ${this.reactions[this.result]} fa-2x ${textColor}`;
    },
    getIsOpen() {
      return this.isOpen ? "active" : "";
    },
  },
};
</script>